import React, {useEffect, useState} from 'react';
import './App.css';
import {Col, Container, Row, Modal, Button, Form, Spinner} from 'react-bootstrap';
import Questions from "./Questions";

function App() {

  const [questions, setQuestions] = useState([]);
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [questionStep, setQuestionStep] = useState(0);
  const [show, setShow] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch('https://www.a5tien.nl/wp-json/wp/v2/vragen?per_page=100')
      .then(res => res.json())
      .then(data => handleResults(data))
    ;
  }, []);

  useEffect(() => {
    let newObj = {};
    Object.keys(answers).forEach(group => {
      newObj[group] = [];
      answers[group].forEach((answer, index) => {
        let newAnswer = {...answer};
        newAnswer.id = index;
        newObj[group].push(newAnswer);
      });
    });


    fetch('https://www.a5tien.nl/wp-json/questions/v1/answer', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email,answers: newObj})
    }).then(res => res.json())
      .then(res => {
        //(res);
      });
  }, [answers]);

  const handleResults = (results) => {
    let returnData = [];
    let newData = [];
    let answerData = [];

    // Parse questions into levels
    results.forEach(result => {
      if (newData[result.niveau] === undefined) {
        newData[result.niveau] = [];
      }

      newData[result.niveau].push({id: result.id, question: result.title.rendered, content: result.content.rendered});
    });

    Object.keys(newData).forEach(() => {
      answerData.push([]);
    });
    setAnswers(answerData);

    // Sort levels by name
    const orderedData = Object.keys(newData).sort().reduce(
      (obj, key) => {
        obj[key] = newData[key];
        return obj;
      },
      {}
    );

    // Order questions by name
    Object.keys(orderedData).forEach(key => {
      returnData.push({
        title: key,
        questions: orderedData[key].sort((a, b) => a.question.localeCompare(b.question))
      });
    });

    // Add questions to state
    setQuestions(returnData);
  };

  const renderImages = () => {
    let images = [];
    Object.keys(answers).forEach(group => {
      answers[group].forEach((answer, index) => {
        if (answer.answer === false) {
          return;
        }
        const filename = (parseInt(group) + 1) + '-' + answer.imageNumber + '.png';

        images.push(filename);
      })
    });

    return images;
  };

  const postForm = (e) => {
    setLoading(true);
    e.preventDefault();
    fetch('https://www.a5tien.nl/wp-json/questions/v1/start', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email})
    }).then(res => res.json())
      .then(res => {
        if (res.success) {
          //setAnswers(res.answers);
          setShow(false);
        }

        setLoading(false);
      });
  };

  renderImages();

  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={6} className="img-container-col">
          <div className="img-container">
            <img src={'/img/leeg.png'} />
            {renderImages().map((image, index) => <img key={image} src={`/img/${image}`} style={{zIndex: (index + 1)}} />)}
          </div>
        </Col>
        <Col sm={12} md={6} className="justify-content-center align-self-center">
          <Questions
            currentQuestion={questionStep}
            setCurrentQuestion={setQuestionStep}
            data={questions}
            level={step}
            setStep={setStep}
            answers={answers}
            setAnswers={setAnswers}
            email={email}
          />
        </Col>
      </Row>


        <Modal show={show} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Toets je merkstrategie met de A5tien Merkradar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={postForm}>
              <p>
                Als ondernemer werk je dagelijks áán je bedrijf. Maar die dagelijkse praktijk vraagt soms om even afstand te nemen. Daar helpt de A5tien Merkradar mee. Het geeft inzicht in de stand van jullie merkstrategie. Aan de hand van het A5tien-model worden prioriteiten duidelijk en laat het zien waar de potentie voor ontwikkeling ligt. Dat is koersvast groeien naar een A-merk.
              </p>
              <p>
                De Merkradar volgt de 5 stappen van het A5tien-model. Elke stap bevat 8 ja-nee vragen; voor elk merkbelevingsgebied een vraag. Bij het invullen wordt direct zichtbaar wat de stand van jullie merkstrategie in dat specifieke gebied is. Is jullie merkstrategie in de betreffende stap voldoende ontwikkeld, dan volgt de volgende stap. Waar nog verdere professionalisering mogelijk is geven we praktische tips om mee aan de slag te gaan.
              </p>
              <p>
                Vul naam en e-mailadres in en begin de toets.
              </p>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Naam</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Naam"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email adres</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Vul je email adres in"
                  required
                />
                <Form.Text className="text-muted">
                  Deze gegevens zullen nooit gedeeld worden met anderen.
                </Form.Text>
              </Form.Group>

              <Button type="submit" variant="primary">
                Doorgaan {loading && <Spinner size="sm" animation="border" />}
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
    </Container>
  );
}

export default App;

import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Jumbotron, Modal, Spinner} from 'react-bootstrap';

const Questions = props => {
  const [show, setShow] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailNotSent, setEmailNotSent] = useState(false);

  const {data, answers, setAnswers, setCurrentQuestion, currentQuestion} = props;
  const level = props.level - 1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.level]);

  const answerQuestion = (id, answer, imageNumber) => {
    let currentAnswers = {...answers};


    const totalQuestions = data[level]['questions'].length - 1;
    const questionIndex = data[level]['questions'].findIndex(question => question.id === id);
    let newQuestionIndex = questionIndex;

    if (questionIndex < totalQuestions) {
      newQuestionIndex = questionIndex + 1;
    }

    currentAnswers[level][id] = {
      answer,
      imageNumber
    };

    setAnswers(currentAnswers);
    setCurrentQuestion(newQuestionIndex);
  };

  const getAnswerVariant = (id, answer) => {
    if (answers[level][id] && answers[level][id]['answer'] === answer) {
      return 'primary';
    }
    return 'light';
  }

  const isAbleToGoForward = (questionId) => {
    const totalQuestions = data[level]['questions'].length - 1;
    const questionIndex = data[level]['questions'].findIndex(question => question.id === questionId);

    return questionIndex < totalQuestions;
  };

  const isAbleToGoBackward = (questionId) => {
    const questionIndex = data[level]['questions'].findIndex(question => question.id === questionId);

    return questionIndex !== 0;
  }

  const setPreviousStep = (questionId) => {
    const questionIndex = data[level]['questions'].findIndex(question => question.id === questionId);

    if (isAbleToGoBackward(questionId)) {
      setCurrentQuestion(questionIndex - 1);
    }
  };

  const getCorrectQuestionCount = () => {
    let count = 0;
    Object.keys(answers[level]).forEach((answer => {
      if (answers[level][answer]['answer'] === true) {
        count++;
      }
    }));

    return count;
  };

  const getQuestionCount = () => {
    if (data[level] === undefined) {
      return 0;
    }
    return data[level]['questions'].length;
  }

  const getAnsweredQuestionCount = () => {
    if (answers[level] === undefined) {
      return 0;
    }
    return Object.keys(answers[level]).length;
  };

    const emailResults = () => {
      setEmailLoading(true);
      setEmailSent(false);
      setEmailNotSent(false);
      fetch('https://www.a5tien.nl/wp-json/questions/v1/email', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: props.email})
      }).then(res => res.json())
        .then(res => {
          setEmailLoading(false);

          if (res.success) {
            setEmailSent(true);
          } else {
            setEmailNotSent(true);
          }
        });
    }

  const question = data[level] ? data[level]['questions'][currentQuestion] : {};
  const imageNumber = currentQuestion + 1;

  return (
    <Fragment>
      {data[level] &&
      <div style={{padding:20}}>
        <Row>
          <Col>
            <h3 style={{fontWeight: 'bold'}}>{data[level].title}</h3>

            {level === 0 && <p>In Stap 1 gaat het over hoe goed jullie als organisatie de gebruiker van je merk aanvoelen. Het gaat over het inleven in je interne én externe klant. Je stakeholders. Wat weet je over hun behoeften? Over hun pijnen en problemen? En ben je je bewust van de ingesleten aannames die er intern over je klanten leven?</p>}
            {level === 1 && <p>In deze tweede stap draait het om jullie interne organisatie. Wat zie je als je in de spiegel kijkt? Of zou je willen zien voor de volgende stap in het professionaliseren van je bedrijf? De reflecties aan de hand van deze vragen bepalen je bij jullie (merk)identiteit.</p>}
            {level === 2 && <p>De aantrekkelijkheid van je organisatie voor klanten en (nieuwe) medewerkers ligt in een doordacht verhaal of merkconcept. Een idee dat gevormd is op het snijvlak van jullie propositie/aanbod en de behoeften en verlangens van je klant/doelgroep. Aantrekkingskracht is een centraal merkverhaal waar alles om draait.</p>}
            {level === 3 && <p>Dit is de proef op de som voordat je breed de markt benaderd. Ga niet als een olifant door de porseleinkast, maar start zorgvuldig volgens een doordacht beleid. Door eerst te testen vanuit verschillende pilots. Kleine deelprojecten die bij succes zijn op te schalen. Laat resultaten voor zich spreken en pak daar vervolgens mee door. Zo creëren we draagvlak voor de grote uitrol.</p>}
            {level === 4 && <p>In deze laatste stap komt het aan op ondernemerschap. Laat zien dat je woorden omzet in daden. In activiteiten die je bedrijf vanuit merkstrategie verder helpen. Van je organisatie een A-merk maken in de beleving van je klanten, medewerkers en stakeholders.</p>}
          </Col>
        </Row>

        {Object.keys(question).length > 0 &&
        <div key={question.id}>
          <Row>
            <Col>
              <h3 style={{fontWeight: 'bold', marginBottom: 0}}>Vraag {imageNumber}/{data[level]['questions'].length}</h3>
              <h4 style={{marginTop: 0}}>{question.question.substring(3)}</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              {isAbleToGoBackward(question.id) &&
              <Button
                className="answer-button previous"
                style={{float: 'left'}}
                onClick={() => setPreviousStep(question.id)}
                variant="light"
              >
                Vorige
              </Button>
              }
            </Col>
            <Col sm={9} style={{textAlign: 'right'}}>
              <Button
                className="answer-button left"
                variant={getAnswerVariant(question.id, true)}
                style={{width: 150, marginRight: -3}}
                onClick={() => answerQuestion(question.id, true, imageNumber)}
              >
                Ja
              </Button>

              <Button
                className="answer-button right"
                variant={getAnswerVariant(question.id, false)}
                style={{width: 150}}
                onClick={() => answerQuestion(question.id, false, imageNumber)}
              >
                Nee
              </Button>
            </Col>
          </Row>
          {!isAbleToGoForward(question.id) &&
          <Row style={{marginTop: 40, fontWeight: 'bold'}}>
            {getAnsweredQuestionCount() === getQuestionCount() && getCorrectQuestionCount() >= 5 &&
            <Col>
              <p>
                In deze stap hebben jullie je merkstrategie al goed ingevuld. Dit geeft je voldoende basiskennis om door te kunnen naar de volgende stap, het intern vertalen van deze kennis naar visie op problemen en kansen.
              </p>

              {props.level < Object.keys(props.answers).length &&
              <Button
                style={{float: 'right'}}
                onClick={() => {
                  props.setStep(props.level + 1);
                  setCurrentQuestion(0);
                }}
              >
                Naar volgende stap »
              </Button>
              }


              {emailSent === false &&
                <Button style={{float: 'left'}} onClick={() => emailResults()}>
                  Ontvang jullie Merkradar met praktische tips » {emailLoading && <Spinner size="sm" animation="border" />}
                </Button>
              }
              {emailSent && <span style={{float: 'left', color: '#E5004E'}}>Merkradar verstuurd, check je mailbox!</span>}
              {emailNotSent && <div>Er ging iets mis, probeer het opnieuw.</div>}
            </Col>
            }

            {getAnsweredQuestionCount() === getQuestionCount() && getCorrectQuestionCount() < 5 &&
            <Col>
              <p>
                Het lijkt erop dat merkstrategie in deze stap nog meer aandacht en focus mag krijgen. Bijvoorbeeld door een goed begrip van deze 8 belevingsgebieden. Dat helpt je om de juiste keuzes te maken voor je merkstrategie. Vervolgens kun je gericht aan de slag naar de volgende stap. Wil je jullie merkstrategie op elk van deze belevingsgebieden verbeteren? A5tien biedt verschillende oplossingen om je organisatie vanuit merkstrategie te professionaliseren.
              </p>
              {emailSent === false &&
                <Button onClick={() => emailResults()}>
                  Ontvang jullie Merkradar met praktische tips » {emailLoading && <Spinner size="sm" animation="border" />}
                </Button>
              }
              {emailSent && <span style={{float: 'left', color: '#E5004E'}}>Merkradar verstuurd, check je mailbox!</span>}
              {emailNotSent && <div>Er ging iets mis, probeer het opnieuw.</div>}
            </Col>
            }

          </Row>
          }
        </div>
        }
      </div>
      }

      <div style={{clear: 'both'}}/>

      {/*props.level > 1 &&
        <Button
          style={{float: 'left'}}
          onClick={() => props.setStep(props.level - 1)}
        >
          Vorige
        </Button>
      */}


      {/*props.level < Object.keys(props.answers).length &&
        <Button
          style={{float: 'right'}}
          onClick={() => {
            const questionAmount = data[level].questions.length;
            const answerAmount = Object.keys(props.answers[level]).length;

            console.log(questionAmount);
            console.log(answerAmount);

            if (questionAmount !== answerAmount) {
              setShow(true);
            }
            props.setStep(props.level + 1);
          }}
        >
          Volgende
        </Button>
      */}

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Fout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Vul a.u.b. alle vragen in</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
;

Questions.propTypes =
{
  data: PropTypes.array,
    level
:
  PropTypes.number,
    setStep
:
  PropTypes.func,
    setCurrentQuestion
:
  PropTypes.func,
    currentQuestion
:
  PropTypes.number
}
;

export default Questions;
